.searchWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px 10px 30px;
}

.searchBar {
    width: 40%;
    height: 40px !important;
    border: 1px solid #979797;
    max-width: 421px;
}

.addNewBtn {
    width: 220px !important;
    height: 40px !important;
    display: flex !important;
    align-items: center !important;
    border-radius: 0 !important;
    background-color: #5EB3E4 !important;
    padding: 0 10px !important;
}

.filtersSection {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
    padding-left: 30px;
}

.dropDowns {
    background-color: #F4F6F7;
    color: #333333;
    font-size: 14px;
    border: none;
    border-bottom: 1px solid #333333;
    margin-right: 20px;
    cursor: pointer;
}

.dropDowns:focus {
    outline: none;
}