.searchWrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 30px;
}

.searchBar {
    width: 40%;
    height: 40px !important;
    border: 1px solid #979797;
    max-width: 421px;
}

.filtersSection {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 30px;
    min-height: 48px;
}

.tableHeader {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1F764E;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.tableHeaderTitle {
    font-size: 16px;
    color: white;
}

.paginationContainer {
    margin-top: auto;
    margin-bottom: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    user-select: none;
    background-color: white;
    padding: 0;
    color: #7d7a7a;
}

.paginationContainer > li {
    margin: 20px;
}

.paginationContainer > .previous {
    font-size: 20px;
    padding: 5px;
    font-weight: bold;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.paginationContainer > .next {
    font-size: 20px;
    padding: 5px;
    font-weight: bold;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.paginationContainer > li > a {
    cursor: pointer;
    color: #7d7a7a;
}

.active {
    border: 2px solid #7d7a7a;
    border-radius: 50%;
    padding: 5px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}