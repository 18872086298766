.leaflet-container {
    height:100%;
    margin: 0 auto;
}

.leaflet-control-layers {
    visibility: hidden !important;
}

.leaflet-tile {
    text-align:left !important;
}

.map-comtainer {
    text-align:left !important;
    position: absolute;
    transition: all .3s ease-in-out;
    transition-property: top,left,bottom,right,width;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.sr-only {
    display: none;
}