.institutional-layer-label-tooltip.leaflet-tooltip {
    background-color: rgba(255, 255, 255, 0);
    opacity: 0.5;
    border: hidden;
    box-shadow: none;
}

.institutional-layer-label-tooltip-text {
    font-size: 16px;
    font-weight: bold;
}
