.filtersContainerOpen {
    width: 100%;
    box-sizing: border-box;
    /* display: flex;
    align-items: center; */

    max-height: 200px;
    transition: max-height 0.5s ease;
    overflow: visible;

    
}

.filtersContainerClosed {
    width: 100%;
    max-height: 0;
    transition: max-height 0.5s ease;
    overflow: hidden;
}

.fieldDropdown {
    width: 100%;
    box-sizing: border-box;
}


.filterIcon {
    color: rgb(88, 180, 229);
    width: 37px !important;
    height: 38px !important;
    align-self: flex-end;
}

.filterIcon:hover {
    cursor: pointer;
    color: white;
    background-color: #4FA2CD;
}

.filtersTitle {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #58B4E5;
    margin-left: 160px;
}

.allFieldsContainer {
    display: flex;
    flex-wrap: wrap;
    min-height: 50px;
    align-content: flex-start;
    margin-bottom: 50px;
}

.fieldLabel {
    height: 24px;
    padding: 2px 5px;
    border: 1px solid gray;
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin: 5px;
}

.fieldDelete {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fieldDelete:hover {
    background-color: #58B4E5;
    color: white;
}

.deleteIcon {
    width: 15px;
    height: 15px;
    color: #58B4E5;
}


.fieldDelete:hover .deleteIcon {
    color: white;
}

/* ------------------------------------- TABLA  */

.content_container {
    margin-top: 8px;
    width: 100%;
}

.content_border {
    background: #126038;
    border-radius: 20px;
    width: 100%;
    height: 2px;
}

/* .table_div {
    overflow-y: auto;
    max-height: 260px;
    transition: height 0.2s;
} */

.table {
    width: 100%;
    border-collapse: collapse;
    display: table;
}

.table_header {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #58B4E5;
    text-align: left;
    padding: 8px 0px 8px 8px;
    display: table-cell;
}

.table_row {
    border-bottom: solid 1px rgba(0, 0, 0, .07);
    display: table-row;
}

.table_row_odd {
    border-bottom: solid 1px rgba(0, 0, 0, .05);
    background: #f8f8f8;
    display: table-row;
}

.table_header_end {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #58B4E5;
    text-align: end;
    padding: 8px 8px 8px 16px;
    display: table-cell;
}

.table_row_text {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #333;
    text-align: left;
    padding: 8px 0px 8px 8px;
    display: table-cell;
}

.table_row_end {
    text-align: end;
    padding-right: 8px;
    display: table-cell;
}

.delete_button_table {
    margin-top: 4px;
    cursor: pointer;
    user-select: none;
    margin-right: 4px;
    padding-left: 10px;
}

.addedspaceOn {
    max-height: 200px;
    transition: max-height 0.5s ease;
    overflow: visible;
}

.addedspaceOff {
    max-height: 0;
    transition: max-height 0.5s ease;
    overflow: hidden;
}

.resetFiltersIcon {
    color: rgb(88, 180, 229);
    width: 37px !important;
    height: 38px !important;
    align-self: flex-end;
    margin-bottom: 10px;
    margin-left: 10px;
}

.resetFiltersIcon:hover {
    cursor: pointer;
    color: white;
    background-color: #4FA2CD;
}

.editModeIcon {
    color: rgb(88, 180, 229);
    width: 24px !important;
    height: 24px !important;
    margin-top: 4px;
    margin-right: 4px;
    
}

.editModeIcon:hover {
    cursor: pointer;
    color: white;
    background-color: #4FA2CD;
}

.editModeIconChecked {
    color: white;
    background-color: #4FA2CD;
    width: 24px !important;
    height: 24px !important;
    margin-top: 4px;
    margin-right: 4px;
}

.editModeIconChecked:hover {
    cursor: pointer;
    color: rgb(88, 180, 229);
    background-color: white;
}