.comment > div {
    height: 100%;
}

.comment > div > input {
    height: 150px;
}

.commentLabel {
    font-family: inherit;
    font-size: inherit;
    margin-top: 30px;
    display: block;
    font-size: 13px;
    line-height: 24px;
}

.commentBox {
    resize: none;
    
    padding: 10px;
    width: 552px;
    height: 100px;
    box-sizing: border-box;
    font-family: inherit;
    font-size: inherit;
    font-size: 13px;
    line-height: 24px;
}

.commentBox:focus .commentLabel {
    display: block;
}

.commentBox:disabled {
    color: black;
}

.paginationContainer {
    margin-top: auto;
    margin-bottom: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    user-select: none;
    background-color: white;
    padding: 0;
    color: #7d7a7a;
}

.paginationContainer > li {
        margin: 20px;
    }

    .paginationContainer  .previous {
        font-size: 20px;
        padding: 5px;
        font-weight: bold;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

   .paginationContainer .next {
        font-size: 20px;
        padding: 5px;
        font-weight: bold;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .paginationContainer > li > a {
        cursor: pointer;
        color: #7d7a7a;
    }

.active {
    border: 2px solid #7d7a7a;
    border-radius: 50%;
    padding: 5px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.expenseType:disabled {
    color: black !important;
}