html {
    font-family: 'Roboto', sans-serif;
}

body {
    font-size: 13px;
    line-height: 24px;
    margin: 0;
    overflow-x: hidden;
    background-color: #F4F6F7;
}

@import url("./carousel.css");
@import url("./dateRange.css");
@import url("../fonts/icomoon/style.css");

[class^="icon-"],
[class*=" icon-"] {
    font-size: 30px;
}

a {
    text-decoration: none;
    color: #FFFFFF;
}

svg:not(:root) {
    overflow: visible;
}

.loginIcon {
    margin: 0px 4px;
}

.logOutIcon {
    font-size: 32px;
}

.menuMoreButton {
    height: 45px !important;
    min-height: 35px !important;
    font-size: 30px !important;
    top: 25% !important;
    background: blue;
    background-color: #282bee;
    /* position: absolute !important; */
}

.filter-add-rn {
    align-self: end;
    margin-top: 15px;
    margin-right: 10px;
    /* margin-left: 10px; */
    font-size: 20px;
    color: white;
    /* font-weight: 500; */

}

.panel-width93 {
    margin: 20px;
    display: -webkit-box;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.moreIcon {
    font-size: 32px !important;
    color: #ffffff;
}

.layerIcon {
    font-size: 24px !important;
}

.tabContainer {
    padding: 20px;
    border-bottom: 3px !important;
    border-bottom-style: solid !important;
}

.dashboard-cards {
    width: 60% !important;
    margin: 10px !important;
    position: relative !important;
    float: right !important;
    border-color: #DCDCDC !important;
    border-style: solid !important;
    border-width: 1.5px !important;
    padding-bottom: 10px !important;
}

.dashboard-cards-full {
    width: 100% !important;
    margin: 10px !important;
    position: relative !important;
    float: right !important;
    border-color: #DCDCDC !important;
    border-style: solid !important;
    border-width: 1.5px !important;
    padding-bottom: 10px !important;
}

.dashboard-cards-agronom {
    width: 90% !important;
    margin: 10px !important;
    border-color: #DCDCDC !important;
    border-style: solid !important;
    border-width: 1.5px !important;
    padding-bottom: 10px !important;
}

.dashboard-title {
    font-size: 20px !important;
    padding: 10px !important;
    width: 100% !important;
    height: 30px !important;
    background-color: #DCDCDC !important;
}

.tableCss {
    border-color: white !important;
}

.onHover {
  cursor: pointer;
}

.menuIcon {
    font-size: 24px;
    color: #ffffff;
    margin-right: 10px;
    padding-right: 20px;
    width: 20px;
}

.menuIcon2 {
    font-size: 27px;
    color: #ffffff;
    width: 27px;
}

.icon {
    display: flex;
    padding: 10px;
    font-size: 27px;
    color: #ffffff;
    width: 27px;
}

.dashboardIcon {
    font-size: 25px;
    color: #ffffff;
}

.dashboardIcon-selected {
    font-size: 25px;
    color: #04764e;
}

.closeIcon {
    font-size: 27px;
    color: #ffffff;
}

.filter-panel-dashboard {
    float: right;
    margin-right: 30px;
}

.filter-label {
    margin: 10px;
    font-weight: bold;
}

.multi-select-in-filter-dashboards {
    width: 180px;
    max-width: 250px !important;
    margin: 10px;
}

.multi-select-in-filter-dashboards-wo {
    width: 460px;
    max-width: 690px !important;
    margin: 10px;
}

.multi-select-in-filter-activity {
    width: 550px;
    max-width: 550px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin: 10px !important;
}

.multi-select-in-filter-wo {
    width: 550px;
    max-width: 550px !important;
    padding: 20px !important;
    margin: 10px !important;
}

.multi-select-activity-form {
    width: 550px;
    max-width: 550px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin: 10px;
}

.multi-select-activity-form-new {
    width: 550px;
    max-width: 550px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    z-index: 3000;
}

.multi-select-activity-form-new2 {
    width: 550px;
    max-width: 550px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    z-index: 2500;
}

.multi-select-activity-form-new3 {
    width: 550px;
    max-width: 550px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    z-index: 2000;
}

.dashboardDrawer {
    border-radius: 0% !important;
    width: 56px !important;
}

.headerIcon {
    font-size: 16px;
    color: #ffffff;
}

/* input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #FFFFFF inset;
} */

/* ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ffffff;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #a0a39f;
} */

/* ::-webkit-scrollbar-thumb:hover {
    background: #5e5c5c; */
/* } */

::-webkit-scrollbar {
    width: 13px;
    height: 8px;
}

/* ::-webkit-scrollbar-track { */
/* box-shadow: inset 0 0 3px #ffffff; */
/* border-radius: 10px; */
/* } */

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #a5a5a5;
}

::-webkit-scrollbar-thumb:hover {
    background: #7c7c7c;
}

.logo {
    padding-top: 25px;
    padding-bottom: 20px;
}

.login {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../images/pozadina_grozdje.svg');
    background-size: 60%;
    background-repeat: repeat-x;
    background-color: #04764e;
    background-attachment: fixed;
    background-position: bottom;
    -webkit-background-size: center;
    -moz-background-size: center;
    -o-background-size: center;
    width: 100%;
    height: 100%;
    position: fixed;
}

.login2 {
    margin: 0 auto;
    background-image: url('../images/wheat.png');
    background-size: 90%;
    background-repeat: repeat-x;
    background-color: #04764e;
    background-attachment: fixed;
    background-position: bottom;
    -webkit-background-size: center;
    -moz-background-size: center;
    -o-background-size: center;
    width: 100%;
    height: 100%;
    position: fixed;
    padding-top: 1%;
}

.profile {
    width: 284px;
    padding: 10px 0px;
    bottom: 0px;
    height: 60px;
    /* position: absolute; */
    display: flex;
    flex-direction: row;
}

.profile-avatar {
    width: 60px !important;
    height: 60px !important;
    margin: 0px 2px;
    background-color: #FFFFFF !important;
    margin-left: 8px;
    margin-right: 8px;
    align-self: center;
}

.userProfile-avatar {
    width: 100px !important;
    height: 100px !important;
    margin: 5px !important;
}

.userProfile-img {
    margin: 10px;
    flex-direction: row;
    display: flex;
    overflow-y: visible !important;
}

.dialogOverlow {
    overflow-y: visible !important;

}

.profile-typography {
    font-weight: 500 !important;
    color: white !important;
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    font-size: 18px !important;
    align-self: center;
    text-align: center !important;
}

.profile-typography2 {
    color: white !important;
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    font-size: 14px !important;
    align-self: center;
    text-align: center !important;
}

.profile-typography b {
    color: black !important;
}

.listInsideMenu {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 140px);
    width: 100%;
}

.expansion-panel {
    width: 260px;
}

.expansion-panel-summary {
    padding: 0px 18px !important;
}

.expansion-panel-summary:hover {
    padding: 0px 14px !important;
    background-color: #058457 !important;
    background: #058457 !important;
}

.expansion-panel-summary-selected {
    padding: 0px 12px !important;
}

.expansion-panel-detail {
    padding: 1px 5px !important;
}

.expansion-panel-detail-selected {
    padding: 1px 0px !important;
}

[class^="MuiExpansionPanelSummary-expandIcon"],
[class*=" MuiExpansionPanelSummary-expandIcon"] {
    right: 15px !important;
}

.tr-head-50 {
    max-width: 50px;
}

.tr-head-60 {
    max-width: 60px;
}

.tr-head-90 {
    max-width: 90px;
}

.tr-head-100 {
    max-width: 100px;
}

.tr-head-120 {
    max-width: 120px;
}

.tr-head-150 {
    width: 150px !important;
}

.icon-summary {
    color: white !important;
    margin-right: 15px !important;
    font-size: 35px !important;
}

.icon-detail {
    color: white !important;
    margin-right: 15px !important;
    font-size: 25px !important;
}

.loginText {
    text-align: center;
    vertical-align: middle;
    color: white;
    line-height: 50px;
    height: 50px;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    margin: 0 auto;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.loginButton {
    width: auto;
}

.page {
    padding: 0px;
    width: 100%;
    /* display: flex; */
}

.pagination-top {
    background-color: #f9f9f9;
}

.ReactTable .-pagination {
    float: right;
    /* width: 480px; */
    padding-right: 14px;
}

.ReactTable .rt-thead .rt-resizable-header {
    overflow: hidden;
}

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
    flex: 0;
    text-align: center;
}

.ReactTable .-pagination .-btn {
    padding: 0 12px !important;
    width: 80px;
}

.rt-td {
    text-align: left;
    width: 100%;
}

.rt-resizable-header-content {
    text-align: left;
    width: 100%;
    white-space: normal;
}

.table-header {
    position: absolute;
    padding-top: 6px;
    z-index: 1;
}

.divcontainer {
    align-self: center;
}

.react-select-dropdown {
    width: 256px;
    max-width: 256px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.action-button {
    width: 30px !important;
    height: 30px !important;
    min-height: 30px !important;
    font-size: 24px !important;
    margin-left: 10px !important;
}

.action-button-checkbox {
    height: 30px !important;
    min-height: 30px !important;
    font-size: 24px !important;
    margin-left: 10px !important;
}

.action-button-filter {
    width: 30px !important;
    height: 30px !important;
    min-height: 30px !important;
    font-size: 24px !important;
    right: 45px !important;
    margin-top: 10px !important;
    position: absolute !important;
}

.rt-tr-group:nth-child(2n-1) {
    background-color: #f9f9f9;
}

.rt-thead.-filters .rt-th {
    overflow: visible;
    text-align: left;
}

.newSensorList .rt-tr-group {
    height: 60px;
}

.newSensorList .rt-resizable-header-content {
    /* text-align: left; */
    width: 100%;
    white-space: normal;
}

.newSensorList .rt-td {
    text-align: left;
    /* width: 40px; */
    height: 100%;
    /* width: fit-content !important; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.newSensorList .rt-resizable-header-content {
    text-align: center;
    width: 100%;
    white-space: normal;
}

.pivot-without-paging {
    padding-top: 40px
}

.DateRangePicker {
    white-space: initial;
    width: 100%;
}

.roundedBox {
    display: flex;
    width: 350px;
    margin: 0 auto;
    display: 'inline-block';
    border-radius: 2px !important;
    align-items: center;
    justify-content: center;
    padding: 20;
}

.loginContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px 65px 50px 65px;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 8px 0px #333232;
}

.DateRangePickerInput__showClearDates {
    padding-right: 0px;
}

.DateRangePickerInput_clearDates {
    border-radius: 50%;
    padding: 4px;
    margin: 0px;
    background-color: #e2e2e2;
}

.numberFilterForm>* {
    width: 100%;
}

.ReactTable {
    display: block;
    border: 0px;
    width: 100%;
}

.table-drawer {
    width: calc(100% - 57px);
}

.DateInput {
    width: 86px;
}

.select__multi-value__label {
    word-wrap: break-word;
    white-space: normal !important;
}

.select__value-container {
    padding: 2px 0px !important;
}

/* frozen column magic */

.frozen {
    order: -1;
    position: relative;
    left: 0;
    transition: left 0.2s;
    margin-right: 6px;
}

.dropZone-profile {
    width: 30px;
    position: relative;
    height: auto;
    margin: auto 0px 5px !important;
}

.dropZone-doc {
    width: 95%;
    position: relative;
    height: auto;
    border-width: 2px;
    border-color: rgb(102, 102, 102);
    border-style: dashed;
    border-radius: 5px;
}

.dropZone-doc-act {
    width: 95%;
    position: relative;
    height: auto;
    border-width: 2px;
    border-color: #58B4E5;
    border-style: solid;
    border-radius: 5px;
    background-color: #a0a39f;
}

.dropZone-doc-acpt {
    width: 95%;
    position: relative;
    height: auto;
    border-width: 2px;
    border-color: #92d442;
    border-style: solid;
    border-radius: 5px;
}

.dropZone-doc-rej {
    width: 95%;
    position: relative;
    height: auto;
    border-width: 2px;
    border-color: #f05e44;
    border-style: solid;
    border-radius: 5px;
}

.dropZone-doc-dis {
    width: 95%;
    position: relative;
    height: auto;
    border-width: 2px;
    border-color: rgb(66, 65, 65);
    border-style: dashed;
    border-radius: 5px;
}

.dropZoneIcon {
    font-size: 36px;
    color: #4c6cfc;
}

.dropZoneDiv {
    text-align: center;
    vertical-align: middle;
}

.dropZone-fil {
    padding-left: 20px
}

.documentGridList {
    width: 100%;
    max-height: 400px;
}

.gps-report-panel {
    width: 800px;
    height: 550px;
}

.gps-report-map-container {
    height: 90%;
    width: 100%;
}

.profile-buttons {
    margin: 20px 17px !important;
    display: block !important;
}

.buttonsPP {
    margin: 8px 4px;
}

.documentTileBar {
    height: auto !important;
}

/* Novi css */

.responsive-dialog-title {
    color: #ffffff !important;
    font-size: 22px !important;
    font-weight: 500;
    padding: 15px 24px 15px 24px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.responsive-dialog-title2 {
    color: #ffffff !important;
    font-size: 22px !important;
    font-weight: 500;
    padding: 15px 24px 15px 24px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.add-rn-button {
    align-self: baseline !important;
    min-height: 36px !important;
    color: #ffffff !important;
    font-size: 22px !important;
    font-weight: 500 !important;
    margin: 8px !important;
    height: 20px !important;
    padding: 0px 24px 0px 24px !important;
    border: 1px solid gray !important;
}

.cancel-rn-button {
    min-height: 36px !important;
    color: #ffffff !important;
    font-size: 22px !important;
    font-weight: 500 !important;
    margin: 8px !important;
    height: 20px !important;
    padding: 0px 24px 0px 24px !important;
    border: 1px solid gray !important;
}

.crop-rotation-info {
    border: 1px solid #04764e !important;
    font-size: 22 px !important;
    font-weight: 500 !important;
    color: black !important;
}

.buttons {
    display: flex;
    margin-top: 15px !important;
}

.documents {
    margin-top: 7px !important;
}

.profileImage {
    padding-top: 15px !important;
}

.spiner {
    width: 100% !important;
}

.weather-icon-size {
    width: 48px;
    height: 48px;
    filter: drop-shadow(3px 3px 2px rgb(87, 87, 87));
    opacity: 0.9;
}

.weather-icon-box {
    background-color: transparent;
    border: 0px;
    text-align: center;
}

.leaflet-div-icon {
    background-color: transparent;
    border: 0px;
}

.map-selects {
    margin-left: 11px;
    max-width: 92%;
    margin-bottom: 0px;
    margin-top: 0px;
}

.css-19bqh2r {
    fill: white !important;
}

.css-d8oujb {
    opacity: 0;
}

[class^="MuiLinearProgress-bar"],
[class*="MuiLinearProgress-bar"] {
    background-color: #333333 !important;
}