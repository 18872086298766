
.content {
    padding-top: 20px;
    min-height: 400px;
    justify-content: space-between;
}

.center {
    text-align: end;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.font {
    font-size: 16px;
    line-height: 36px;
    margin-left: 20px;
}