@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?lw3065');
  src:  url('fonts/icomoon.eot?lw3065#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?lw3065') format('truetype'),
    url('fonts/icomoon.woff?lw3065') format('woff'),
    url('fonts/icomoon.svg?lw3065#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Season:before {
  content: "\ea7f";
}
.icon-PodTabla:before {
  content: "\ea5f";
}
.icon-TableLista:before {
  content: "\ea5e";
}
.icon-cropMarker-2:before {
  content: "\ea3b";
}
.icon-cropMarker-1 .path1:before {
  content: "\ea3d";
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path2:before {
  content: "\ea3e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path3:before {
  content: "\ea3f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path4:before {
  content: "\ea42";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path5:before {
  content: "\ea43";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path6:before {
  content: "\ea45";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path7:before {
  content: "\ea46";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path8:before {
  content: "\ea47";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path9:before {
  content: "\ea48";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path10:before {
  content: "\ea49";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path11:before {
  content: "\ea4a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path12:before {
  content: "\ea4b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path13:before {
  content: "\ea4c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path14:before {
  content: "\ea4d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path15:before {
  content: "\ea4e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path16:before {
  content: "\ea4f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path17:before {
  content: "\ea50";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path18:before {
  content: "\ea51";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path19:before {
  content: "\ea52";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path20:before {
  content: "\ea53";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path21:before {
  content: "\ea54";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path22:before {
  content: "\ea56";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path23:before {
  content: "\ea57";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path24:before {
  content: "\ea58";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path25:before {
  content: "\ea59";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path26:before {
  content: "\ea5a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path27:before {
  content: "\ea5b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path28:before {
  content: "\ea5c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cropMarker-1 .path29:before {
  content: "\ea5d";
  margin-left: -1em;
  color: rgb(239, 65, 54);
}
.icon-Pecurke:before {
  content: "\ea38";
}
.icon-Hortukulturne:before {
  content: "\ea39";
}
.icon-Predator-01:before {
  content: "\ea35";
}
.icon-Mamac-01:before {
  content: "\ea36";
}
.icon-Feremonske-Klopke-01:before {
  content: "\ea37";
}
.icon-Straight:before {
  content: "\ea25";
}
.icon-Complex:before {
  content: "\ea26";
}
.icon-Micronutrients:before {
  content: "\ea27";
}
.icon-Farmyard-manure:before {
  content: "\ea28";
}
.icon-Green-manure:before {
  content: "\ea29";
}
.icon-Compost:before {
  content: "\ea2a";
}
.icon-Vermicompost:before {
  content: "\ea2b";
}
.icon-Insecticides:before {
  content: "\ea2c";
}
.icon-Fungicides:before {
  content: "\ea2d";
}
.icon-Herbicides:before {
  content: "\ea2e";
}
.icon-Repellents:before {
  content: "\ea2f";
}
.icon-Rodenticides:before {
  content: "\ea30";
}
.icon-Rest:before {
  content: "\ea31";
}
.icon-Disel:before {
  content: "\ea32";
}
.icon-DrvenaAmbalaza:before {
  content: "\ea33";
}
.icon-Cocoa2:before {
  content: "\ea34";
}
.icon-DopunskaDjubrivaStartnaIDjubrivaZaPrihranu:before {
  content: "\e9bf";
}
.icon-DjubrivaZaTretiranjeSetvenogSadnogMaterijala:before {
  content: "\e9a7";
}
.icon-RatarskaKultura:before {
  content: "\e9a8";
}
.icon-PovrtarskeKulture:before {
  content: "\e9a9";
}
.icon-StaklenaAmbalaza:before {
  content: "\e9aa";
}
.icon-AromatineZacinskeILekoviteKulture:before {
  content: "\e9ab";
}
.icon-ProizvodiPovrtarskihKultura:before {
  content: "\e9ae";
}
.icon-ProizvodiRatarskihKultura:before {
  content: "\e9af";
}
.icon-ProizvodiAromatinihZainskihILekovitihKultura:before {
  content: "\e9a3";
}
.icon-ProizvodiVocaIVinoveLoze:before {
  content: "\e9a4";
}
.icon-RegulatoriRasta:before {
  content: "\e9a5";
}
.icon-PreparatiSpravljeniNaGazdinstvu:before {
  content: "\e9a6";
}
.icon-Supstrati:before {
  content: "\e9a2";
}
.icon-SredstvaZaTretiranjeSemena:before {
  content: "\e9b1";
}
.icon-SadniceVocaIVinoveLoze:before {
  content: "\e9be";
}
.icon-Rasad:before {
  content: "\e9b2";
}
.icon-PomocnaSredstva:before {
  content: "\e9b3";
}
.icon-PlastinaAmbalaza:before {
  content: "\e9b4";
}
.icon-PapirnaAmbalaza:before {
  content: "\e9b5";
}
.icon-OsnovnaDjubriva:before {
  content: "\e9b6";
}
.icon-Insekticidi:before {
  content: "\e9b7";
}
.icon-HortikulturneKulture:before {
  content: "\e9b8";
}
.icon-Herbicidi:before {
  content: "\e9b9";
}
.icon-Gorivo:before {
  content: "\e9bc";
}
.icon-Fungicidi:before {
  content: "\e9bd";
}
.icon-Cocoa .path1:before {
  content: "\e9a1";
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path2:before {
  content: "\e9c0";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path3:before {
  content: "\e9c1";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path4:before {
  content: "\e9c2";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path5:before {
  content: "\e9c3";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path6:before {
  content: "\e9c4";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path7:before {
  content: "\e9c9";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path8:before {
  content: "\e9ca";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path9:before {
  content: "\e9cc";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path10:before {
  content: "\e9cd";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path11:before {
  content: "\e9ce";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path12:before {
  content: "\e9cf";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path13:before {
  content: "\e9d0";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path14:before {
  content: "\e9d1";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path15:before {
  content: "\e9d2";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path16:before {
  content: "\e9d3";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path17:before {
  content: "\e9d4";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path18:before {
  content: "\e9d5";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path19:before {
  content: "\e9d6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path20:before {
  content: "\e9da";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path21:before {
  content: "\e9db";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path22:before {
  content: "\e9dc";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path23:before {
  content: "\e9dd";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path24:before {
  content: "\e9de";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path25:before {
  content: "\e9f8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path26:before {
  content: "\e9f9";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path27:before {
  content: "\e9fa";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path28:before {
  content: "\ea05";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path29:before {
  content: "\ea06";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path30:before {
  content: "\ea0a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path31:before {
  content: "\ea0b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path32:before {
  content: "\ea0f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path33:before {
  content: "\ea10";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path34:before {
  content: "\ea11";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path35:before {
  content: "\ea12";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path36:before {
  content: "\ea13";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path37:before {
  content: "\ea14";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path38:before {
  content: "\ea18";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path39:before {
  content: "\ea19";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path40:before {
  content: "\ea1a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path41:before {
  content: "\ea1b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path42:before {
  content: "\ea1c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path43:before {
  content: "\ea1d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path44:before {
  content: "\ea1e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path45:before {
  content: "\ea1f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path46:before {
  content: "\ea20";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path47:before {
  content: "\ea21";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path48:before {
  content: "\ea22";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cocoa .path49:before {
  content: "\ea23";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path50:before {
  content: "\ea24";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path51:before {
  content: "\ea60";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path52:before {
  content: "\ea61";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path53:before {
  content: "\ea62";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path54:before {
  content: "\ea63";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path55:before {
  content: "\ea64";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path56:before {
  content: "\ea65";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path57:before {
  content: "\ea66";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path58:before {
  content: "\ea67";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path59:before {
  content: "\ea68";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path60:before {
  content: "\ea69";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path61:before {
  content: "\ea6a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path62:before {
  content: "\ea6b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path63:before {
  content: "\ea6c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path64:before {
  content: "\ea6d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path65:before {
  content: "\ea6e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path66:before {
  content: "\ea6f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path67:before {
  content: "\ea70";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path68:before {
  content: "\ea71";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path69:before {
  content: "\ea72";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path70:before {
  content: "\ea73";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path71:before {
  content: "\ea74";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path72:before {
  content: "\ea75";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path73:before {
  content: "\ea76";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path74:before {
  content: "\ea77";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path75:before {
  content: "\ea78";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path76:before {
  content: "\ea79";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path77:before {
  content: "\ea7a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path78:before {
  content: "\ea7b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path79:before {
  content: "\ea7c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path80:before {
  content: "\ea7d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Cocoa .path81:before {
  content: "\ea7e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Solanum:before {
  content: "\e9a0";
}
.icon-uniE905:before {
  content: "\e905";
}
.icon-AgroLife:before {
  content: "\e906";
}
.icon-home:before {
  content: "\e907";
}
.icon-Beleske:before {
  content: "\e908";
}
.icon-newspaper:before {
  content: "\e909";
}
.icon-pencil:before {
  content: "\e90a";
}
.icon-Biljka:before {
  content: "\e90b";
}
.icon-Buba:before {
  content: "\e90c";
}
.icon-CaseLil:before {
  content: "\e90d";
}
.icon-Claas:before {
  content: "\e90e";
}
.icon-CvekPrska:before {
  content: "\e90f";
}
.icon-Detelina:before {
  content: "\e910";
}
.icon-image:before {
  content: "\e911";
}
.icon-images:before {
  content: "\e98c";
}
.icon-camera:before {
  content: "\e98d";
}
.icon-Detuz:before {
  content: "\e98e";
}
.icon-Djubrivo:before {
  content: "\e915";
}
.icon-Dokument:before {
  content: "\e916";
}
.icon-Dokument1:before {
  content: "\e918";
}
.icon-Drvo:before {
  content: "\e91a";
}
.icon-Fabrika:before {
  content: "\e91b";
}
.icon-Fendt:before {
  content: "\e91d";
}
.icon-Fotoaparat:before {
  content: "\e91f";
}
.icon-Grabulje:before {
  content: "\e921";
}
.icon-Grasak:before {
  content: "\e923";
}
.icon-Iks:before {
  content: "\e926";
}
.icon-Informacija:before {
  content: "\e928";
}
.icon-Izmeni:before {
  content: "\e929";
}
.icon-Izmeni1:before {
  content: "\e92b";
}
.icon-JohnDeere:before {
  content: "\e98b";
}
.icon-Kafa:before {
  content: "\e98a";
}
.icon-Kalendar:before {
  content: "\e989";
}
.icon-Kanistar:before {
  content: "\e92d";
}
.icon-Kanta:before {
  content: "\e930";
}
.icon-KanticaZaUlje:before {
  content: "\e931";
}
.icon-file-empty:before {
  content: "\e934";
}
.icon-files-empty:before {
  content: "\e936";
}
.icon-file-text:before {
  content: "\e938";
}
.icon-Kap:before {
  content: "\e939";
}
.icon-Karfiol:before {
  content: "\e93b";
}
.icon-Kljuc:before {
  content: "\e93d";
}
.icon-Kofa:before {
  content: "\e940";
}
.icon-Kombajn:before {
  content: "\e941";
}
.icon-Krava:before {
  content: "\e944";
}
.icon-Kupi:before {
  content: "\e945";
}
.icon-stack:before {
  content: "\e983";
}
.icon-Kutija:before {
  content: "\e984";
}
.icon-Laverda:before {
  content: "\e985";
}
.icon-List:before {
  content: "\e946";
}
.icon-Lista:before {
  content: "\e947";
}
.icon-ListURuci:before {
  content: "\e948";
}
.icon-Lokacija:before {
  content: "\e949";
}
.icon-price-tag:before {
  content: "\e94a";
}
.icon-price-tags:before {
  content: "\e94b";
}
.icon-barcode:before {
  content: "\e94c";
}
.icon-Lopata:before {
  content: "\e94d";
}
.icon-LopataZaOranje:before {
  content: "\e94e";
}
.icon-Lopov:before {
  content: "\e950";
}
.icon-MarketTorba:before {
  content: "\e951";
}
.icon-MassevFerguson:before {
  content: "\e952";
}
.icon-MedicinskiKofer:before {
  content: "\e953";
}
.icon-Merac:before {
  content: "\e982";
}
.icon-credit-card:before {
  content: "\e981";
}
.icon-NavigacioniTab:before {
  content: "\e980";
}
.icon-NewHolland:before {
  content: "\e954";
}
.icon-phone:before {
  content: "\e955";
}
.icon-NoviRadniNalog:before {
  content: "\e956";
}
.icon-Pamuk:before {
  content: "\e957";
}
.icon-Plus:before {
  content: "\e958";
}
.icon-Pomoc:before {
  content: "\e959";
}
.icon-Povrce:before {
  content: "\e95a";
}
.icon-Prikaciti:before {
  content: "\e95b";
}
.icon-PrikljucnaMasina:before {
  content: "\e95c";
}
.icon-Prodaj:before {
  content: "\e95d";
}
.icon-map:before {
  content: "\e94f";
}
.icon-Profil:before {
  content: "\e95e";
}
.icon-Prskalica:before {
  content: "\e95f";
}
.icon-Pumpica:before {
  content: "\e97d";
}
.icon-Redo:before {
  content: "\e97e";
}
.icon-Repa:before {
  content: "\e97f";
}
.icon-Rostselmash:before {
  content: "\e960";
}
.icon-Sampo:before {
  content: "\e961";
}
.icon-Sargarepa:before {
  content: "\e962";
}
.icon-Stiklic:before {
  content: "\e963";
}
.icon-StrelicaDole1:before {
  content: "\e964";
}
.icon-Table:before {
  content: "\e965";
}
.icon-Telefon:before {
  content: "\e966";
}
.icon-mobile:before {
  content: "\e967";
}
.icon-Traktor:before {
  content: "\e968";
}
.icon-Travka:before {
  content: "\e969";
}
.icon-TrenutnaLokacija:before {
  content: "\e96a";
}
.icon-Undo:before {
  content: "\e96b";
}
.icon-Uzvicnik:before {
  content: "\e96c";
}
.icon-box-add:before {
  content: "\e97c";
}
.icon-box-remove:before {
  content: "\e97b";
}
.icon-Vatra:before {
  content: "\e97a";
}
.icon-Vise:before {
  content: "\e96d";
}
.icon-VoceIGrozdje:before {
  content: "\e96e";
}
.icon-Web:before {
  content: "\e96f";
}
.icon-ZalivanjeIzKantice:before {
  content: "\e970";
}
.icon-Zito:before {
  content: "\e971";
}
.icon-Zubcanik:before {
  content: "\e972";
}
.icon-bubbles:before {
  content: "\e973";
}
.icon-bubbles2:before {
  content: "\e974";
}
.icon-user:before {
  content: "\e975";
}
.icon-users:before {
  content: "\e976";
}
.icon-user-plus:before {
  content: "\e977";
}
.icon-user-minus:before {
  content: "\e978";
}
.icon-user-check:before {
  content: "\e979";
}
.icon-search1:before {
  content: "\e986";
}
.icon-zoom-in:before {
  content: "\e987";
}
.icon-zoom-out:before {
  content: "\e988";
}
.icon-lock:before {
  content: "\e98f";
}
.icon-unlocked:before {
  content: "\e990";
}
.icon-equalizer:before {
  content: "\e992";
}
.icon-cog:before {
  content: "\e994";
}
.icon-aid-kit:before {
  content: "\e998";
}
.icon-bug:before {
  content: "\e999";
}
.icon-bin:before {
  content: "\e9ac";
}
.icon-bin2:before {
  content: "\e9ad";
}
.icon-truck:before {
  content: "\e9b0";
}
.icon-list:before {
  content: "\e9ba";
}
.icon-list2:before {
  content: "\e9bb";
}
.icon-download:before {
  content: "\e9c5";
}
.icon-upload:before {
  content: "\e9c6";
}
.icon-download2:before {
  content: "\e9c7";
}
.icon-upload2:before {
  content: "\e9c8";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-star-empty:before {
  content: "\e9d7";
}
.icon-star-half:before {
  content: "\e9d8";
}
.icon-star-full:before {
  content: "\e9d9";
}
.icon-warning:before {
  content: "\ea07";
}
.icon-notification:before {
  content: "\ea08";
}
.icon-question:before {
  content: "\ea09";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-cancel-circle:before {
  content: "\ea0d";
}
.icon-blocked:before {
  content: "\ea0e";
}
.icon-play:before {
  content: "\ea15";
}
.icon-pause:before {
  content: "\ea16";
}
.icon-stop:before {
  content: "\ea17";
}
.icon-arrow-right:before {
  content: "\ea3c";
}
.icon-arrow-left:before {
  content: "\ea40";
}
.icon-circle-up:before {
  content: "\ea41";
}
.icon-circle-left:before {
  content: "\ea44";
}
.icon-google:before {
  content: "\ea88";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-tux:before {
  content: "\eabd";
}
.icon-apple:before {
  content: "\eabe";
}
.icon-finder:before {
  content: "\eabf";
}
.icon-android:before {
  content: "\eac0";
}
.icon-windows:before {
  content: "\eac1";
}
.icon-windows8:before {
  content: "\eac2";
}
.icon-file-pdf:before {
  content: "\eadf";
}
.icon-file-openoffice:before {
  content: "\eae0";
}
.icon-file-word:before {
  content: "\eae1";
}
.icon-file-excel:before {
  content: "\eae2";
}
.icon-libreoffice:before {
  content: "\eae3";
}
.icon-Amaranthus:before {
  content: "\e99a";
}
.icon-Beverage-crops-product:before {
  content: "\e997";
}
.icon-Cabbage:before {
  content: "\e996";
}
.icon-Cashew:before {
  content: "\e991";
}
.icon-Cassava:before {
  content: "\e993";
}
.icon-Cereal-or-grain-crops-product:before {
  content: "\e995";
}
.icon-Coconut:before {
  content: "\e9df";
}
.icon-Coffee:before {
  content: "\e9e0";
}
.icon-Corchorus:before {
  content: "\e9e1";
}
.icon-Cotton:before {
  content: "\e9e2";
}
.icon-Cowpea:before {
  content: "\e9e3";
}
.icon-Date-palm:before {
  content: "\e9e4";
}
.icon-Forage-legume:before {
  content: "\e9e5";
}
.icon-Groundnut:before {
  content: "\e9e6";
}
.icon-Irish-potato:before {
  content: "\e9e7";
}
.icon-Kenaf:before {
  content: "\e9e8";
}
.icon-Kola:before {
  content: "\e9e9";
}
.icon-Maize:before {
  content: "\e9ea";
}
.icon-Melon:before {
  content: "\e9eb";
}
.icon-Oil-crops-product:before {
  content: "\e9ec";
}
.icon-Oil-palm:before {
  content: "\e9ed";
}
.icon-Okra:before {
  content: "\e9ee";
}
.icon-Pearl-millet:before {
  content: "\e9ef";
}
.icon-Pepper:before {
  content: "\e9f0";
}
.icon-Potato:before {
  content: "\e9f1";
}
.icon-Raphia-palm:before {
  content: "\e9f2";
}
.icon-Rice:before {
  content: "\e9f3";
}
.icon-Root-and-tuber-crops-product:before {
  content: "\e9f4";
}
.icon-Rubber:before {
  content: "\e9f5";
}
.icon-Sesame:before {
  content: "\e9f6";
}
.icon-Sokoyokoto:before {
  content: "\e9f7";
}
.icon-Sorghum:before {
  content: "\e9fb";
}
.icon-Soybean:before {
  content: "\e9fc";
}
.icon-Sugarcane:before {
  content: "\e9fd";
}
.icon-Sunflower:before {
  content: "\e9fe";
}
.icon-Sweet-orange:before {
  content: "\e9ff";
}
.icon-Sweet-potato:before {
  content: "\ea00";
}
.icon-Tangelo:before {
  content: "\ea01";
}
.icon-Tomato:before {
  content: "\ea02";
}
.icon-Wheat:before {
  content: "\ea03";
}
.icon-Yam:before {
  content: "\ea04";
}
.icon-Beverage-crops-product2:before {
  content: "\e99e";
}
.icon-Cereal-or-grain-crops-product2:before {
  content: "\e99f";
}
.icon-Legumes:before {
  content: "\e99d";
}
.icon-Oil-crops-product2:before {
  content: "\e99c";
}
.icon-Root-and-tuber-crops-product2:before {
  content: "\e99b";
}
.icon-Working-Operation:before {
  content: "\e900";
}
.icon-Twiter:before {
  content: "\e901";
}
.icon-Tri-Tacke:before {
  content: "\e902";
}
.icon-Tiketing:before {
  content: "\e903";
}
.icon-Settings:before {
  content: "\e904";
}
.icon-Search:before {
  content: "\e912";
}
.icon-Resources:before {
  content: "\e913";
}
.icon-Material:before {
  content: "\e914";
}
.icon-Map:before {
  content: "\e917";
}
.icon-Machines:before {
  content: "\e919";
}
.icon-LogOut:before {
  content: "\e91c";
}
.icon-LogIn:before {
  content: "\e91e";
}
.icon-Location:before {
  content: "\e920";
}
.icon-linkedin:before {
  content: "\e922";
}
.icon-Instagram:before {
  content: "\e924";
}
.icon-Implements:before {
  content: "\e925";
}
.icon-Hamburger:before {
  content: "\e927";
}
.icon-Government:before {
  content: "\e92a";
}
.icon-G:before {
  content: "\e92c";
}
.icon-Fields:before {
  content: "\e92e";
}
.icon-Farmers:before {
  content: "\e92f";
}
.icon-Facebook:before {
  content: "\e932";
}
.icon-Delete:before {
  content: "\e933";
}
.icon-Crop:before {
  content: "\e935";
}
.icon-Common:before {
  content: "\e937";
}
.icon-ArrowLevo:before {
  content: "\e93a";
}
.icon-ArrowGore:before {
  content: "\e93c";
}
.icon-ArrowDole:before {
  content: "\e93e";
}
.icon-ArrowDesno:before {
  content: "\e93f";
}
.icon-AddFarmer:before {
  content: "\e942";
}
.icon-Add:before {
  content: "\e943";
}
.icon-radio-checked2:before {
  content: "\ea55";
}
.icon-feed:before {
  content: "\ea3a";
}
.icon-pie-chart:before {
  content: "\ea80";
}
.icon-table2:before {
  content: "\ea81";
}
.icon-podcast:before {
  content: "\ea82";
}
.icon-coin-euro:before {
  content: "\ea83";
}
.icon-search:before {
  content: "\f002";
}
.icon-filter:before {
  content: "\f0b0";
}
.icon-cut:before {
  content: "\f0c4";
}
.icon-scissors:before {
  content: "\f0c4";
}
